import React, { memo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import classnames from 'classnames';

import './PreFooter.scss';

import GatsbyImage from 'gatsby-image';
import { graphql } from 'gatsby';
import TwoOverlappingImages from '../TwoOverlappingImages/TwoOverlappingImages';
import { CTALink } from '../CTA';

import { sanitizer, customPropTypes, useCSSMotion, track, useCTAClickTracking, useSectionTimeSpent } from '../../util';

function PreFooter({
  data: {
    id,
    name,
    title,
    description,
    theme,
    overflow,
    croppingImage,
    hasPreFooterImmediatelyAfter,
    horizontallyReversed,
    imageGroup,
    image,
    cta,
    withWaves,
  },
  ...options
}) {
  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);
  const withLightBackground = ['blue', 'grey', 'white'].includes(theme);
  const withTwoOverlappingImages = !!imageGroup?.front;

  const additionalClassNames = {
    'exclude-transitions': options.excludeTransitions,
    'horizontally-reversed': horizontallyReversed,
    'full-bleed': withTwoOverlappingImages,
    'layout-with-two-overlapping-images': withTwoOverlappingImages,
    'with-waves': withWaves,
    'has-prefooter-after': hasPreFooterImmediatelyAfter,
    'image-overflow': overflow,
    'cropping-image': croppingImage,
    'light-background': withLightBackground,
  };

  const handleHtmlClick = useCallback(({ target }) => {
    if (target instanceof HTMLAnchorElement && target.protocol === 'mailto:') {
      track('ContactUs', { name: 'EmailPress' });
    }
  }, []);

  const handleCtaClick = useCTAClickTracking(name);

  useSectionTimeSpent(name, containerRef);

  return (
    <section
      id={id}
      className={classnames('PreFooter', `theme-${theme}`, additionalClassNames, motionState)}
      ref={containerRef}
    >
      <div className="colored-full-bleed-container">
        <div className="container">
          <div className="colored-container">
            {withTwoOverlappingImages ? (
              <TwoOverlappingImages
                data={{
                  ...imageGroup,
                  portrait: true,
                }}
              />
            ) : (
              <div className="image">
                <GatsbyImage
                  fluid={image?.localFile.childImageSharp.fluid}
                  alt={image?.altText}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
            )}
            <div className="content">
              {title && <h3 className="title">{title}</h3>}
              <div
                className="description"
                onClick={handleHtmlClick}
                dangerouslySetInnerHTML={{ __html: sanitizer(description) }}
              />
              {cta?.link && (
                <CTALink
                  onClick={handleCtaClick}
                  link={cta.link}
                  text={cta.text}
                  ariatext={cta.text}
                  white={!withLightBackground}
                  motionState={motionState}
                />
              )}
            </div>
          </div>
        </div>
        {withWaves && <div className="waves-container" />}
      </div>
    </section>
  );
}

PreFooter.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    title: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.oneOf(['dark-purple', 'purple', 'green', 'grey', 'blue', 'white']),
    image: customPropTypes.image,
    imageGroup: PropTypes.shape({
      front: customPropTypes.image,
      back: customPropTypes.image,
    }),
    withWaves: PropTypes.bool,
    horizontallyReversed: PropTypes.bool,
    hasPreFooterImmediatelyAfter: PropTypes.bool,
    croppingImage: PropTypes.bool,
    overflow: PropTypes.bool,
    excludeTransitions: PropTypes.bool,
    imageOverflow: PropTypes.bool,
    cta: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
});

export default memo(PreFooter);

export const fragments = graphql`
  fragment PreFooter on WpPage_Flexiblelayout_FlexibleChildren_PreFooter {
    croppingImage
    cta {
      link
      text
    }
    description
    hasPreFooterImmediatelyAfter
    horizontallyReversed
    image {
      ...FluidImage
    }
    imageGroup {
      back {
        ...FluidImage
      }
      front {
        ...FluidImage
      }
    }
    overflow
    theme
    title
    id
    jumpToLinkTitle
    name
    withWaves
  }

  fragment PreFooter_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_PreFooter {
    croppingImage
    cta {
      link
      text
    }
    description
    hasPreFooterImmediatelyAfter
    horizontallyReversed
    image {
      ...FluidImage
    }
    imageGroup {
      back {
        ...FluidImage
      }
      front {
        ...FluidImage
      }
    }
    overflow
    theme
    title
    id
    jumpToLinkTitle
    name
    withWaves
  }
`;
