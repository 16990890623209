import React, { memo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import classnames from 'classnames';
import { TweenMax } from 'gsap/all';

import './TwoOverlappingImages.scss';

import GatsbyImage from 'gatsby-image';
import { graphql } from 'gatsby';
import { customPropTypes, useParallaxMotion, parallaxEventListener, useGsapMotion } from '../../util';

function TwoOverlappingImages({ data: { id, backImage, frontImage, back, front, portrait }, ...props }) {
  const containerRef = useRef(null);

  const backImageRef = useRef(null);
  const frontImageRef = useRef(null);

  const backImageImgRef = useRef(null);
  const frontImageImgRef = useRef(null);

  // TODO: remove when schema is corrected
  backImage = back ? back : backImage;
  frontImage = front ? front : frontImage;

  const animateInit = useCallback(() => {
    TweenMax.set(backImageRef.current, {
      yPercent: portrait ? 15 : -17.5,
    });

    TweenMax.set(frontImageRef.current, {
      yPercent: portrait ? -15 : 20,
    });

    TweenMax.set([backImageImgRef.current, frontImageImgRef.current], {
      scale: 1.3,
    });
  }, [portrait]);

  const animateIn = useCallback(() => {
    TweenMax.to([backImageRef.current, frontImageRef.current], 1, {
      yPercent: 0,
      onUpdate: function () {
        if (parallaxEventListener) {
          window.dispatchEvent(parallaxEventListener);
        }
      },
    });
    TweenMax.to([backImageImgRef.current, frontImageImgRef.current], 1, {
      scale: 1,
    });
  }, []);

  useGsapMotion({ ref: containerRef, animateIn, animateInit });

  useParallaxMotion(backImageRef, !portrait);
  useParallaxMotion(frontImageRef, !!portrait);

  return (
    <div id={id} ref={containerRef} className={classnames('TwoOverlappingImages', { portrait, landscape: !portrait })}>
      <div className="aspect-ratio-container">
        <div ref={backImageRef} className="back-image">
          <div ref={backImageImgRef} className="image-wrapper">
            <GatsbyImage
              className="image"
              fluid={backImage?.localFile.childImageSharp.fluid}
              alt={backImage?.altText}
            />
          </div>
        </div>

        <div ref={frontImageRef} className="front-image">
          <div ref={frontImageImgRef} className="image-wrapper">
            <GatsbyImage
              className="image"
              fluid={frontImage?.localFile.childImageSharp.fluid}
              alt={frontImage?.altText}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

TwoOverlappingImages.propTypes = checkExtraProps({
  data: PropTypes.shape({
    front: customPropTypes.image.isRequired,
    back: customPropTypes.image.isRequired,
    portrait: PropTypes.bool,
    ...customPropTypes.basePropTypes,
  }),
});

TwoOverlappingImages.defaultProps = {};

export default memo(TwoOverlappingImages);

export const fragments = graphql`
  fragment TwoOverlappingImages on WpPage_Flexiblelayout_FlexibleChildren_TwoOverlappingImages {
    portrait
    backImage {
      ...FluidImage
    }
    frontImage {
      ...FluidImage
    }
    id
    jumpToLinkTitle
    name
  }

  fragment TwoOverlappingImages_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_TwoOverlappingImages {
    portrait
    backImage {
      ...FluidImage
    }
    frontImage {
      ...FluidImage
    }
    id
    jumpToLinkTitle
    name
  }
`;
